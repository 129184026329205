import superagent from 'superagent'

export default class GraphQLRequest {
  constructor () {
    this.endpoint = process.env.REACT_APP_GRAPHQL_URL
  }

  send (payload, isUpload = false) {
    const request = superagent
      .post(this.endpoint)
      .set('Authorization', `Bearer ${localStorage.getItem('apiToken')}`)

    if (isUpload) {
      const { query, variables } = payload

      const operations = {
        query,
        variables
      }

      const map = {
        '0': ['variables.input.file'] // Adjust this path as needed for your schema
      }

      // Attach fields
      request.field('operations', JSON.stringify(operations)) // Add GraphQL query/mutation and variables
      request.field('map', JSON.stringify(map)) // Map file field to GraphQL variable
      request.attach('0', variables.input.file) // Attach the file with the key matching the map
    } else {
      // For non-upload requests, send JSON payload
      request.send(payload)
    }

    return request.then(response => {
      const { body } = response
      if (body.errors) throw body.errors[0]
      return body.data
    })
  }
}
