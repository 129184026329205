import React, { Component, lazy as Lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// redux
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers'
import Login from './components/Pages/Login'
import LoggerService from './services/logger.service'
import qs from 'qs'

LoggerService.init()

// pages
const ErrorNotFound = Lazy(() => import('./components/Pages/Error'))
// const Account = Lazy(() => import('./components/Pages/Account'))
// const Activate = Lazy(() => import('./components/Pages/Activate'))
// const Recovery = Lazy(() => import('./components/Pages/Recovery'))
const Dashboard = Lazy(() => import('./components/Pages/Dashboard'))
const EventCreate = Lazy(() => import('./components/Pages/EventCreate'))
const EventDashboard = Lazy(() => import('./components/Pages/EventDashboard'))
const EventEditInformation = Lazy(() => import('./components/Pages/EventEditInformation'))
const EventEditInvitations = Lazy(() => import('./components/Pages/EventEditInvitations'))
const EventEditSessions = Lazy(() => import('./components/Pages/EventEditSessions'))
const EventEditGroups = Lazy(() => import('./components/Pages/EventEditGroups'))
// const EventEditReview = Lazy(() => import('./components/Pages/EventEditReview'))
const EventEditStaff = Lazy(() => import('./components/Pages/EventEditStaff'))
const ImportRouter = Lazy(() => import('./components/Pages/ImportRouter'))
const Reports = Lazy(() => import('./components/Pages/Reports'))
const Timetable = Lazy(() => import('./components/Pages/Reports/Timetable'))
const EventAttendance = Lazy(() => import('./components/Pages/Reports/EventAttendance'))
const UserManager = Lazy(() => import('./components/Pages/UserManager'))
const Notifications = Lazy(() => import('./components/Pages/Notifications'))
const Maintenance = Lazy(() => import('./components/Pages/Maintenance'))
const ParentActivity = Lazy(() => import('./components/Pages/Reports/ParentActivity'))
// const ParentGuestEvent = Lazy(() => import('./components/Pages/ParentGuestEvent'))
const ParentItinerary = Lazy(() => import('./components/Pages/Reports/ParentItinerary'))
const ParentCommunications = Lazy(() => import('./components/Pages/ParentCommunications'))
const Settings = Lazy(() => import('./components/Pages/Settings'))
const TeacherItinerary = Lazy(() => import('./components/Pages/Reports/TeacherItinerary'))
const TeacherActivity = Lazy(() => import('./components/Pages/Reports/TeacherActivity'))

const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true'
const maintenanceMessage = process.env.REACT_APP_MAINTENANCE_MESSAGE

const initialState = {}
const enhancers = []
const middleware = [thunk]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(reducers, initialState, composedEnhancers)
const persistor = persistStore(store)

const redirectToParentsPortal = (uri = '') => {
  window.location.replace('https://parents.events.schoolbooking.com' + uri + window.location.search)
  return null
}

const redirectToParentsPortalActivation = () => {
  return redirectToParentsPortal(
    window.location.href.replace('activate', 'activate-account').replace('identifier', 'email')
  )
}

const redirectToGuestParentsPortal = () => {
  const token = window.location.href.split('/guest/')[1]

  if (!token) {
    return redirectToParentsPortal()
  }

  return redirectToParentsPortal(`/parentsmeeting/guest/${token}`)
}

const redirectToParentsPortalIfParent = (isEvent = false) => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

  if (!params.token && !localStorage.getItem('apiToken')) {
    return isEvent ? redirectToParentsPortalEvent() : redirectToParentsPortal()
  }
  return <Dashboard />
}

const redirectToParentsPortalEvent = () => {
  const newUrl = window.location.href.replace(
    'events.schoolbooking.com',
    'parents.events.schoolbooking.com/parentsmeeting'
  )

  window.location.replace(newUrl)
  return null
}

// Render our app.
class App extends Component {
  render () {
    return (
      <Suspense fallback={<div> Loading... </div>}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              {isMaintenanceMode ? (
                <Maintenance message={maintenanceMessage} />
              ) : (
                <Switch>
                  <Route path='/events/:id/guest/:token' component={() => redirectToGuestParentsPortal()} />
                  <Route path='/events/create' component={EventCreate} />
                  <Route path='/events/:id/edit/sessions' component={EventEditSessions} />
                  <Route path='/events/:id/edit/parents' component={EventEditInvitations} />
                  <Route path='/events/:id/edit/students' component={EventEditGroups} />
                  <Route path='/events/:id/edit/staff' component={EventEditStaff} />
                  <Route path='/events/:id/edit' component={EventEditInformation} />
                  <Route path='/users' component={UserManager} />
                  <Route path='/settings' component={Settings} />
                  <Route path='/events/:id/communications' exact component={ParentCommunications} />
                  <Route path='/events/:id/notifications' exact component={Notifications} />
                  <Route path='/events/:id/:sessionId?' component={EventDashboard} />
                  <Route path='/events/:id' component={EventDashboard} />
                  <Route path='/reports/:eventId' exact component={Reports} />
                  <Route path='/reports/parents/itinerary/:eventId' component={ParentItinerary} />
                  <Route path='/reports/parents/attendance/:eventId/:sessionId?' component={EventAttendance} />
                  <Route path='/reports/parents/activity/:eventId/:sessionId?' component={ParentActivity} />
                  <Route path='/reports/teachers/timetable/:eventId' component={Timetable} />
                  <Route path='/reports/teachers/activity/:eventId/:sessionId?' component={TeacherActivity} />
                  <Route path='/reports/teachers/itinerary/:eventId/:sessionId?' component={TeacherItinerary} />
                  <Route path='/account/:section?' component={() => redirectToParentsPortal('/account')} />
                  <Route path='/imports' component={ImportRouter} />
                  <Route path='/recovery' component={() => redirectToParentsPortal('/recovery')} />
                  <Route path='/activate' component={() => redirectToParentsPortalActivation('/activate-account')} />
                  <Route path='/login' component={Login} />
                  <Route path='/' exact component={() => redirectToParentsPortalIfParent()} />
                  <Route path='/:eventId?' exact component={() => redirectToParentsPortalIfParent(true)} />
                  <Route path='*' component={ErrorNotFound} />
                </Switch>
              )}
            </Router>
          </PersistGate>
        </Provider>
      </Suspense>
    )
  }
}

export default App
