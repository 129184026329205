import { normalize } from 'normalizr'
import APIRequest from '../services/api.service'
import SchemaService from '../services/schema.service'
import GraphQLRequest from '../services/graphql.service'

const clients = {
  deleteAsset: (assetId) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
          mutation deleteAsset($input: DeleteAssetInput!) {
            deleteAsset(input: $input) {
              id
              filename
            }
          }
        `,
        variables: { input: { id: assetId } }
      }

      return graphQLRequest.send(query)
    }
  },

  uploadAsset: (asset, code) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
          mutation uploadAsset($input: UploadAssetInput!) {
            uploadAsset(input: $input) {
              id
              filename
              type {
                id
                code
                rules
              }
            }
          }`,
        variables: { input: { file: asset, code: code } }
      }

      return graphQLRequest.send(query, true)
    }
  },

  list: (includes = []) => {
    return dispatch => {
      dispatch({ type: 'CLIENT_LIST' })
      return APIRequest.get(dispatch, 'clients', includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), [
            SchemaService.schemas.client
          ])

          if (output.entities.venues) {
            dispatch({
              type: 'VENUE_LIST_SUCCESS',
              venues: output.entities.venues
            })
          }

          return dispatch({
            type: 'CLIENT_LIST_SUCCESS',
            clients: output.entities.clients,
            clientList: output.result
          })
        })
        .catch(err => {
          console.error('failed to get client list', err.message)
          dispatch({ type: 'CLIENT_LIST_FAILURE', error: err.message })
          throw err
        })
    }
  },

  logo: (clientId, logo) => {
    const formData = new FormData()
    formData.append('logo', logo)

    return dispatch => {
      dispatch({ type: 'CLIENT_UPDATE', clientId: clientId })

      return APIRequest.post(dispatch, `clients/${clientId}/logo`, formData)
        .then(res => {
          return dispatch({
            type: 'CLIENT_UPDATE_SUCCESS',
            client: res.body.data
          })
        })
        .catch(err => {
          console.error('failed to update client', err.message)
          dispatch({ type: 'CLIENT_UPDATE_FAILURE', error: err.message })
          throw err
        })
    }
  },

  updateSettings: (clientId, input) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
          mutation($id: ID!, $input: UpdateClientSettingInput!) {
            updateClientSetting(client_id: $id, input: $input) {
              id
              client_id
              unbook_on_non_attendance
              phone
              email
            }
          }`,
        variables: { id: clientId, input: input }
      }

      return graphQLRequest.send(query)
    }
  },

  update: (clientId, newProps, includes = []) => {
    return dispatch => {
      dispatch({ type: 'CLIENT_UPDATE', clientId: clientId })
      return APIRequest.patch(dispatch, `clients/${clientId}`, newProps, includes)
        .then(res => {
          return dispatch({
            type: 'CLIENT_UPDATE_SUCCESS',
            client: res.body.data
          })
        })
        .catch(err => {
          console.error('failed to update client', err.message)
          dispatch({ type: 'CLIENT_UPDATE_FAILURE', error: err.message })
          throw err
        })
    }
  },

  get: (id, includes = ['venues', 'staff']) => {
    return dispatch => {
      dispatch({ type: 'CLIENT_GET' })

      return APIRequest.get(dispatch, 'clients/' + id, includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.client)
          return dispatch({ type: 'CLIENT_GET_SUCCESS', client: output.entities.clients[id] })
        })
        .catch(err => {
          console.error('failed to get client', err.message)
          dispatch({ type: 'CLIENT_GET_FAILURE', error: err.message })
          throw err
        })
    }
  },

  listVenues: clientId => {
    return dispatch => {
      dispatch({ type: 'CLIENT_VENUES_LIST', clientId: clientId })
    }
  }
}

export default clients
